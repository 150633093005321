import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
// import { request } from 'http';
import {
  catchError,
  filter,
  take,
  switchMap,
  finalize,
  retry,
  tap,
} from 'rxjs/operators';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private token: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private commonService: CommonService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
     this.commonService.showSpinner();
    const clonedReq = this.handleRequest(req);
    return next.handle(clonedReq);
  }
  // handleRequest(req: HttpRequest<any>) {
  //   let user;
  //   user = localStorage.getItem('accessToken');
  //   let authReq;
  //   if (
  //     (req.method.toLowerCase() === 'post' ||
  //       req.method.toLowerCase() === 'put') &&
  //     req.body instanceof FormData
  //   ) {
  //     authReq = req.clone({
  //       headers: new HttpHeaders({
  //         Authorization: user ? user : '',
  //         'cache-control': 'no-cache',
  //       }),
  //     });
  //   } else {
  //     authReq = req.clone({
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         Authorization: user ? user : '',
  //         'cache-control': 'no-cache',
  //       }),
  //     });
  //   }
  //   return authReq;
  // }
  handleRequest(req: HttpRequest<any>) {
     let user;
    user = localStorage.getItem('accessToken');
    
    let authReq;
    if (
      (req.method.toLowerCase() === 'post' ||
        req.method.toLowerCase() === 'put') && req.body instanceof FormData
    ) {
      console.log('Request Body (FormData):', req.body);
      authReq = req.clone({
        headers: new HttpHeaders({
          Authorization: user ? user : '',
          'cache-control': 'no-cache',
        
          hash: this.commonService.getEncryption((Date.now()).toString()),
          devicetype:"WEB",
         
        }),
      });
    } else {
      console.log('Request Body (FormData):', req.body);
      authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: user ? user : '',
          'cache-control': 'no-cache',
          hash: this.commonService.getEncryption((Date.now()).toString()),
          devicetype:"WEB",
        }),
      });
    }
    return authReq;
  }
}
